package de.clsky.ottenbot

import emotion.react.css
import js.core.jso
import mui.material.Container
import react.FC
import react.Props
import react.create
import react.dom.client.createRoot
import react.router.*
import react.router.dom.createHashRouter
import web.cssom.NamedColor.Companion.white
import web.cssom.em
import web.dom.document
import web.html.HTML.div

fun main() {
    val root = document.createElement(div)
        .also { document.body.appendChild(it) }

    createRoot(root)
        .render(App.create())
}

val hashRouter =
    createHashRouter(
        arrayOf(
            jso {
                path = "/birthday"
                Component = BirthdayApp
            }
        )
    )



private val App = FC<Props> {
    Container {
        css {
            padding = 1.5.em
            backgroundColor = white
        }
        RouterProvider {
            router = hashRouter
        }
    }
}