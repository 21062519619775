package de.clsky.ottenbot

import de.clsky.ottenbot.common.async
import de.clsky.ottenbot.services.birthday.Birthday
import de.clsky.ottenbot.services.birthday.postSaveBirthday
import emotion.react.css
import kotlinx.datetime.LocalDate
import mui.material.*
import mui.material.FormControlVariant.Companion.standard
import mui.material.styles.TypographyVariant.Companion.h5
import muix.pickers.AdapterDateFns
import muix.pickers.CalendarPicker
import muix.pickers.LocalizationProvider
import react.*
import react.dom.html.ReactHTML.div
import react.dom.onChange
import web.cssom.*
import web.cssom.Auto.Companion.auto
import web.html.HTMLInputElement
import web.prompts.alert
import web.window.window
import de.clsky.ottenbot.wrapper.format
import react.router.useLocation
import web.url.URLSearchParams

external interface BirthdayAppProps : Props {
}

val BirthdayApp = FC<BirthdayAppProps> { props ->
    var name by useState("")
    var token = URLSearchParams(useLocation().search)["token"] ?: ""
    var chatId = URLSearchParams(useLocation().search)["chatId"] ?: ""

    var birthDate by useState<LocalDate>()

    Container {
        div {
            css {
                maxWidth = 350.px
                display = Display.block
                marginLeft = auto
                marginRight = auto
            }
            Typography {
                variant = h5
                +"Add Birthday"
            }
            TextField {
                label = ReactNode("Standard")
                variant = standard
                fullWidth = true
                onChange = { e ->
                    val target = e.target as HTMLInputElement
                    name = target.value
                }
            }
            LocalizationProvider {
                dateAdapter = AdapterDateFns
                CalendarPicker {
                    onChange = { selection, _ ->
                        birthDate = LocalDate.parse(format(selection, "yyyy-MM-dd"))
                    }
                }
            }
            Button {
                +"Absenden"
                onClick = { e ->
                    if (birthDate != null) {
                        async {
                            println(birthDate)
                            println(name)
                            postSaveBirthday(Birthday(chatId, token, name, birthDate!!))
                            window.close()
                        }
                    } else {
                        alert("Please select a date first")
                    }
                }
            }
        }
    }
}
