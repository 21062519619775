package de.clsky.ottenbot.common

import kotlin.coroutines.*
import kotlin.js.Promise

/*
    Magic code-Block: Macht eine Coroutine auf und macht somit aus einem suspend ein Promise :)
 */
fun <T> async(block: suspend () -> T): Promise<T> = Promise<T> { resolve, reject ->
    block.run {
        startCoroutine(object : Continuation<T> {
            override val context: CoroutineContext get() = EmptyCoroutineContext
            override fun resumeWith(result: Result<T>) {
                when {
                    result.isFailure -> result.exceptionOrNull()?.let {
                        console.log(it)
                        reject(it)
                    }
                    result.isSuccess -> result.getOrNull()?.let {
                        resolve(it)
                    }
                }
            }
        })
    }
}

suspend fun <T> Promise<T>.await(): T = suspendCoroutine { cont ->
    then({ cont.resume(it) }, { cont.resumeWithException(it) })
}
